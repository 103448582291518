<template>
    <div v-if="user">
        <report-window
            v-if="ready"
            endpoint="/reports/register_activity"
            :perPage="500"
            :fields="fields"
            :title="title"
            :headers="headers"
            :current.sync="current"
            :columnClick="columnClick"
            :templates="templates"
            :slotsFields="slotsFields"
            :updateReport="updateReport"
        ></report-window>
    </div>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import { mapState } from "vuex";
export default {
    name: "register-activity",
    props: [],
    components: {
        ReportWindow,
    },
    computed:{
        ...mapState({
            user: state => state.main.user,
        }),
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        let self = this;
        return {
            current: {},
            updateReport: false,
            ready: false,
            fields: [
                {
                    name: 'FromDate',
                    editor: 'date',
                    label: 'From',
                    required: true
                },
                {
                    name: "FromTime",
                    editor: "time",
                    required: true,
                    label: "UTC Time",
                },
                {
                    name: 'ToDate',
                    editor: 'date',
                    label: 'To',
                    required: true
                },
                {
                    name: "ToTime",
                    editor: "time",
                    required: true,
                    label: "UTC Time",
                },
                {
                    name: "method",
                    editor: "select",
                    label: "Method",
                    addBlank: true,
                    options: [
                        {value: 'GET', label: 'GET'},
                        {value: 'POST', label: 'POST'},
                        {value: 'PUT', label: 'PUT'},
                        {value: 'DELETE', label: 'DELETE'}
                    ],
                },
                {
                    name: "grep",
                    editor: "text",
                    label: "grep",
                }
            ],
            title: "Register Activity",
            headers: {
                UserName: "Register",
                Date: "Date",
                Time: "Time",
            },
            templates: [
                {name: 'RegisterDate', callback: (row) => {
                        if (!row.RegisterDate) return '';
                        return moment.utc(row.RegisterDate).local().format("DD MMM YYYY HH:mm:ss");
                    }
                },
            ],
            columnClick: {},
            slotsFields: [],
        };
    },

};
</script>
