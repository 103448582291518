<template>
      <report-window endpoint="/custom/reports/sales_by_region"
        v-if="ready"
        :fields="fields"
        :title="title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :notFilterable="options && options.notFilterable"
        :current.sync="current"
        :totalsOn="totalsOn"
        :averageOn="averageOn"
        :columnsClasses="columnsClasses"
      ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'sales-by-island-report',
    props: ['options'],
    components: {
        ReportWindow,
    },
    async mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    computed: {
        ...mapState({
        }),
        columnsClasses () {
            return {
                Amount: 'text-right',
                Passengers: 'text-right',
            }
        },
        totalsOn () {
            let r = [ 'Amount', 'Passengers', 'Days', 'DaysPax'];
            return r;
        }
    },
    data () {
        let self = this;
        let bookingView = api.importMixinModule('booking', 'views');
        let bookingStatusOptions = bookingView.bookingStatusOptions;
        return {
            ready: false,
            averageOn: [],
            current: {},
            fields: [
                { name: 'FromDate', editor: 'date', label: 'From', required: true},
                { name: 'ToDate', editor: 'date', label: 'To', required: true},
                { name: 'FilterDate', label: 'Filter Date by', editor: 'select', addBlank: true, required: true,
                    options: [
                        {value: '0', label: 'Sales Date'},
                        {value: '1', label: 'Date Inn'},
                        {value: '2', label: 'Date Out'},
                      ]
                },

                { name: 'SalesUserId', label: 'Seller', editor: 'select',
                    optionLabels: 'UserName', relation: 'user', addBlank: true,
                    filters: (row) => {
                        if (row.Closed) return false;
                        if (row.Department && row.Department.Sales) return true;
                    }
                },
                { name: 'SalesOriginId', label: 'Sales Origin', editor: 'select',
                    optionLabels: 'Name', relation: 'salesorigin', addBlank: true
                },
                { name: 'RegionId', label: 'Island', editor: 'select',
                    optionLabels: 'Name', relation: 'region', addBlank: true
                },
                { name: 'IslandType', label: 'Product Line', editor: 'select', addBlank: true,
                    options: [
                        {value: 'JAVA_BALI', label: 'Java Bali'},
                        {value: 'JAVA_OTHERS', label: 'Java Others'},
                        {value: 'BALI_OTHERS', label: 'Bali Others'},
                        {value: 'OTHERS', label: 'Others'},
                      ]
                },

                { name: 'ExcludeTransit', label: 'Exclude Transit', editor: 'checkbox'},
                //{ name: 'ShowCities', label: 'Show Cities', editor: 'checkbox'},
            ],
            title: 'Sales by Island',
            headers: {},
            templates: [
                { name: 'Status', callback: (row) => {
                        return tr(bookingView.bookingStatus[row.Status]);
                    }
                },
                { name: 'Amount', callback: (row) => {
                      if (!row.Amount) return '';
                      return tools.toNumber(row.Amount, 0);
                    }
                },
                {
                    name: "StartDate",
                    callback: (row) => {
                        if (!row.StartDate) return "";
                        return moment(row.StartDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "EndDate",
                    callback: (row) => {
                        if (!row.EndDate) return "";
                        return moment(row.EndDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "ConfirmedDate",
                    callback: (row) => {
                        if (!row.ConfirmedDate) return "";
                        return moment(row.ConfirmedDate).format("DD/MM/YYYY");
                    }
                },
                {
                    name: "Days",
                    callback: (row) => {
                        if (row.__total_row__) {
                            return (row.DaysPax / row.Passengers).toFixed(1);
                        }
                        return row.Days;
                    }
                },
            ],
        }
    },
    methods: {
    }
}
</script>

