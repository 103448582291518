<template>
      <report-window
        endpoint="/reports/salesbyuser"
        v-if="ready"
        :fields="fields"
        :title="cardTitle || title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :columnsClasses="columnsClasses"
        :notFilterable="options && options.notFilterable"
        :headerColumns="headerColumns"
        :current.sync="current"
        :slotsFields="slotsFields"
        :totalsOn="totalsOn"
        :countOn="countOn"
        :fieldClasses="fieldClasses"
        :columnsFormat="columnsFormat"
        :columnClick="columnClick"
        :beforeRender="beforeRender"
        :requestTimeout="120000"
        ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
import { mapState } from 'vuex';
export default {
    name: 'sales-by-user-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    computed: {
        ...mapState({
            managerActions: state => state.main.managerActions,
            user: state => state.main.user,
        }),
        columnsClasses () {
            return {
                Quoted: 'text-right',
                QuotedBookingCurrency: 'text-right',
                QuotedSalesPrice: 'text-right',
                TotalCost: 'text-right',
                Profit: 'text-right',
            }
        },
        totalsOn () {
            if (!this.showTotals) return [];
            let r = [ 'Quoted', 'TotalCost', 'Profit', 'QuotedBookingCurrency', 'QuotedSalesPrice'];
            if (this.current.BookingStatus == 'ALL_CONFIRMED') r.push('Passengers');
            return r;
        }
    },
    mounted () {
        frontTools.setReportValues(this);
        if (this.user.Department && this.user.Department.Sales && !this.managerActions) {
            this.current.SalesUserId = this.user.id;
            let field = _.find(this.fields, (r) => r.name == 'SalesUserId');
            field.disabled = true;
        }
        this.current = Object.assign({}, this.current);
        this.fields = Object.assign([], this.fields);
        this.ready = true;
    },
    data () {
        return this.getData(this);
    },
    methods: {
        getData (self) {
            let bookingView = api.importMixinModule('booking', 'views');
            let bookingStatusOptions = bookingView.bookingStatusOptions;
            bookingStatusOptions.push({value: 'ALL_CONFIRMED', label: 'All Confirmed'});
            return {
                current: {},
                currencies: api.tables.currency,
                showTotals: true,
                ready: false,
                fields: [
                    { name: 'FromDate', editor: 'date', label: 'From Date'},
                    { name: 'ToDate', editor: 'date', label: 'To Date'},
                    { name: 'FilterDate', label: 'Filter Date by', editor: 'select', addBlank: true, required: true,
                        options: [  
                            {value: '0', label: 'Sales Date'},
                            {value: '1', label: 'Date Inn'},
                            {value: '2', label: 'Date Out'},
                        ]
                    },
                    {name: 'BookingStatus', label: 'File Status', editor: 'select', defValue: 'ALL_CONFIRMED',
                            options: bookingStatusOptions, addBlank: true},
                    { name: 'PeriodId', label: 'Season', editor: 'select',
                        optionLabels: 'Name', relation: 'period', addBlank: true,
                    },
                    { 
                        name: 'SalesUserId', 
                        label: 'Seller', 
                        editor: 'vue-select',
                        list: true,
                        optionLabels: 'UserName', 
                        relation: 'user', 
                        addBlank: true,
                        filters: (row) => {
                            if (row.Closed) return false;
                            if (row.Department && row.Department.Sales) return true;
                        }
                    },
                    { name: 'SalesOriginId', label: 'Sales Origin', editor: 'select',
                        optionLabels: 'Name', relation: 'salesorigin', addBlank: true
                    },
                    { name: 'CustomerTypeId', label: 'Customer Type', editor: 'select',
                        optionLabels: 'Name', relation: 'customertype', addBlank: true,
                    },
                    { name: 'Currency', relation: 'currency', optionLabels: 'id', setRelationTo: 'Currency', 
                        editor: 'select', label: 'Currency'
                    },
                    { 
                        name: 'AgencyId', 
                        relation: 'agency', 
                        optionLabels: 'Name', 
                        editor: 'vue-select', 
                        label: 'Agency'
                    },
                    { name: 'ExcludeCanceled', label: 'Exclude Canceled', editor: 'checkbox'},
                ],
                title: 'Sales by Seller',
                headers: {
                  SalesUser: 'Seller',
                  StartDate: 'Date Inn',
                  EndDate: 'Date Out',
                  ConfirmedDate: 'Sale Date',
                  SalesOrigin: 'Sales Origin',
                  PaxName: 'Passenger',
                  BookingId: 'File Nr',
                  Quoted: 'Sales Price',
                  TotalCost: 'Cost',
                  PriceCurrencyId: 'Currency',
                  Profit: 'Profits'
                },
                headerColumns: ['BookingId', 'PaxName', 'ConfirmedDate', 'Status', 'StartDate', 'EndDate',
                           'SalesOrigin', 'Quoted', 'TotalCost', 'Profit', 'PriceCurrencyId', 'SalesUser', 'Customer'],

                templates: [
                    {name: 'Quoted', callback: (row) => {
                            let v = self.getQuoted(row);
                            if (v) {
                                v = Math.round(v, 2);
                                let r = tools.toNumber(v, 0);
                                return r;
                            };
                        }
                    },
                    {name: 'Status', callback: (row) => {
                            return tr(bookingView.bookingStatus[row.Status]);
                        }
                    },
                    {name: 'TotalCost', callback: (row) => {
                        if (row.calculated && row.TotalCost) {
                            return tools.toNumber(row.TotalCost, 0);
                        }
                        row._TotalCost = row.TotalCost;
                        let r = Math.round(row.TotalCost, 2);
                        return tools.toNumber(r, 0);
                      }
                    },
                    {name: 'Profit', callback: (row) => {
                        let r;
                        if (row.calculated && row.Profit) {
                            row._Profit = row.Profit;
                            r = tools.toNumber(row.Profit, 0);
                            return r;
                        }
                        //let v = travelTools.currencyConvert(row.CurrencyId, self.current.Currency, parseFloat(row.TotalCost), row.CurrencyRates);
                        let v = row.TotalCost;
                        r = self.getQuoted(row) - v;
                        r = Math.round(r, 2);
                        r = tools.toNumber(r, 0);
                        return r;

                      }
                    },
                    {
                        name: "StartDate",
                        callback: (row) => {
                            if (!row.StartDate) return "";
                            return moment(row.StartDate).format("DD/MM/YYYY");
                        }
                    },
                    {
                        name: "EndDate",
                        callback: (row) => {
                            if (!row.EndDate) return "";
                            return moment(row.EndDate).format("DD/MM/YYYY");
                        }
                    },
                    {
                        name: "ConfirmedDate",
                        callback: (row) => {
                            if (!row.ConfirmedDate) return "";
                            return moment(row.ConfirmedDate).format("DD/MM/YYYY");
                        }
                    },


                ],
                slotsFields: [],
                countOn: [ 'PaxName'],
                fieldClasses: {
                    Quoted: (row) => {
                        return self.getFieldStyle(row);
                    },
                    BookingId: (row) => {
                        return 'cursor-pointer-report';
                    },
                },
                columnsFormat: {
                    'Quoted': (value, row) => {
                        let cId = row.PriceCurrencyId;
                        let c = _.find(api.tables.currency, (r) => r.id == cId)
                        let symbol = '$';
                        if (c && c.Symbol) symbol = c.Symbol;
                        if (value) return `${symbol} ${value}`;
                        return value;
                    },
                    'Profit': (value, row) => {
                        let cId = row.PriceCurrencyId;
                        let c = _.find(api.tables.currency, (r) => r.id == cId)
                        let symbol = '$';
                        if (c && c.Symbol) symbol = c.Symbol;
                        if (value) return `${symbol} ${value}`;
                        return value;
                    },
                },
                columnClick: {
                    BookingId: self.showBooking,
                },
            }
        },
        getFieldStyle (row) {
            if (row.QUOTED_NOT_AMOUNT_RECEIVED) return 'exclamation-alert'
            return '';
            let amount;
            //amount =  travelTools.currencyConvert(row.CurrencyId, this.current.Currency, row.FinalTotalWithFlights, row.CurrencyRates);
            amount = row.FinalTotalWithFlights;
            //let quoted =  travelTools.currencyConvert(row.CurrencyId, this.current.Currency, row.QuotedBookingCurrency, row.CurrencyRates);
            quoted = row.QuotedBookingCurrency;
            if (amount/quoted > 1.05 || quoted/amount > 1.05)  return 'exclamation-alert';
            return '';
        },
        getQuoted (row) {
            if (row.calculated && row.Quoted) {
                return row.Quoted;
            }
            let v = row.QuotedSalesPrice;
            if (row.ReceiptsSalesPrice) v = row.ReceiptsSalesPrice;
            return v;
            //return travelTools.currencyConvert(row.PriceCurrencyId, this.current.Currency, v, row.CurrencyRates);
        },
        showBooking (row) {
            vueTools.showBooking(this.$router, 'my-booking', {id: row.BookingId.toString(), TemplateType: 'NONE'});
        },
        async beforeRender (report) {
            if (this.current.BookingStatus == 'ALL_CONFIRMED') {
                if (this.headerColumns.indexOf('Passengers') == -1) {
                    this.headerColumns.push('Passengers')
                    this.headerColumns = Object.assign([], this.headerColumns);
                }
            }
            let currencies = [];
            for (let row of report.dataList) {
                if (currencies.indexOf(row.PriceCurrencyId) == -1) {
                    currencies.push(row.PriceCurrencyId);
                }
            }
            this.showTotals = true;
            if (currencies.length != 1) this.showTotals = false;
            /*
            let model = await api.importMixinModule('booking', 'model');
            for (let row of report.dataList) {
                if (row.ALL_PAYMENTS == -1 ) {
                    let booking = await model.get({id: row.BookingId, isTemplate: false});
                    booking.SummaryCurrencyId = row.PriceCurrencyId;
                    row.TotalCost = booking.finalCost;
                    row.Profit = row.finalIncomes - row.finalCost;
                }
            },
            */
        }
    }
}
</script>

