<template>
    <div id="pstyles" v-if="recordStore && !processing">
        <itinerary
            v-if="!processing"
            :view="true"
            :id="id"
            :version="version"
            :showMap="true"
        ></itinerary>
        <itinerary-footer :langCode="langCode"></itinerary-footer>
    </div>
</template>

<script>
const itinerary = importVueComp('components/itinerary', 'Itinerary');
const itineraryFooter = importVueComp('components/itinerary', 'ItineraryFooter');
import { mapState } from 'vuex';
export default {
    name: 'itinerary-view',
    props: ['id', 'hash', 'version'],
    components: {
        'itinerary': itinerary,
        'itinerary-footer': itineraryFooter,
    },
    data () {
        return {
            record: null,
            langCode: null,
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
    },
    async mounted () {
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r;
        let path = '/public/api/booking/';
        if (this.$router.currentRoute.meta.newsletter) path = '/public/api/newsletter/';
        if (this.version) {
            r = await api.get('/public/api/bookingversion/' + this.hash + '/' + this.id);
        } else {
            r = await api.get(path + this.hash + '/' + this.id);
        }
        if (r) {
            r._public = true;
            if (r.Language) this.langCode = r.Language.Code
            let record = await new model({record: r, notFieldOptions: true});
            this.$store.commit('initRecord', {record});
            this.record = tools.plainObject(this.recordStore);
        }
        api.setProcessing(false)
    },
    beforeDestroy() {
        this.$store.commit('initRecord', {record: null});
    },
}
</script>
